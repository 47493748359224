import { module } from 'modujs';


export default class VideoModal extends module {

  static OPEN = "has-video-modal-open";

  constructor(m) {
    super(m);

    this.$el = this.el;
    this.$video = this.$("video")[0];

    this.events = {
      click: {
        close: 'close'
      }
    }
  }

  init() {

  }

  open(options) {
    const { src } = options
    this.$el.classList.add(VideoModal.OPEN);

    this.play(src)
  }

  play(src) {
    this.$video.src = src
    this.$video.play()
    this.call("stop", null, "Scroll");
  }

  stop() {
    this.$video.removeAttribute("src")
    this.$video.pause()


    // this.$video.muted = true
  }

  close() {
    console.log("close")
    this.$el.classList.remove(VideoModal.OPEN);
    this.call("start", null, "Scroll");
    this.stop()
  }

  destroy() {
    super.destroy()
  }
}