import { module } from 'modujs';
import { Swiper } from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';

export default class extends module {
  constructor(m) {
    super(m);

    this.$el = this.el
    this.$container = this.$el.querySelector(".swiper");

    this.$next = this.$el.querySelector(".swiper-btn.swiper-btn--next");
    this.$pagination = this.$('pagination')[0];
    this.$prev = this.$el.querySelector(".swiper-btn.swiper-btn--prev");

    this.$total = this.$("total")[0];

  }

  init() {

    let current = this.$el.querySelector("[data-carousel='current']")

    Swiper.use([Pagination, Navigation]);

    const args = {
      speed: 500,
      grabCursor: true,
      // rewind: true,
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: this.$next,
        prevEl: this.$prev,
      },
      pagination: {
        el: this.$pagination,
        // type: 'custom',
        // renderCustom: (swiper, current, total) =>  (current) + '-' + (total)
      }
    }

    this.$carousel = new Swiper(this.$container, args)



    let totalSlides = this.$el.querySelectorAll(".swiper-slide").length;

    this.$total.innerText = totalSlides


    this.$carousel.on("slideChange", function (e) {
      let slideNumber = e.realIndex + 1;
      // console.log(current)
      current.innerText = slideNumber;
    });

  }

  destroy() {
    super.destroy()
    this.carousel?.destroy(true, true)
  }
}
