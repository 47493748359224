import { module } from "modujs";

export default class extends module {
  constructor(m) {

    super(m)

    this.$el = this.el
    this.$src = this.getData('src');



  }



  init() {
    console.log("init");

    this.bindEvents();
  }

  bindEvents() {
    this.$el.addEventListener('click', this.active.bind(this))
  }

  unbindEvents() {
    this.$el.removeEventListener('click', this.active.bind(this))
  }

  active(e) {
    const target = e.currentTarget
    const src = target.dataset.src
    console.log(src)
    this.call("open", { src: src }, "VideoModal");

  }

}