import { module } from 'modujs';
import { Swiper } from 'swiper';
import { Scrollbar } from 'swiper/modules';

export default class extends module {
  constructor(m) {
    super(m);

    this.$el = this.el

    this.$scrollbar = this.$("scrollbar")[0];
    this.$swiper = this.$("swiper")[0];

  }

  init() {

    Swiper.use([Scrollbar]);

    const args = {
      speed: 300,
      // slidesPerView: 3,
      grabCursor: true,
      // centeredSlides: true,
      // spaceBetween: 15,
      slidesPerView: 'auto',
      // preventInteractionOnTransition: true,
      slideToClickedSlide: true,
      scrollbar: {
        el: this.$scrollbar,
      },
    }

    this.$carousel = new Swiper(this.$swiper, args)



  }

  destroy() {
    super.destroy()
    this.carousel?.destroy(true, true)
  }
}   
