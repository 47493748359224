import { module } from "modujs";
// import gsap from "gsap";
// import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";

const CHARS = "1234567890qwertyuiopasdfghjklzxcvbnm";


gsap.registerPlugin(ScrambleTextPlugin);

export default class extends module {
  constructor(m) {
    super(m);

    

    this.$el = this.el;
    this.$target = this.$("target");
  }
  init() {

    let textLinkTl = gsap.timeline({
        paused: true,
      });
      
       const OPTION = {
        scrambleText: {
          chars: CHARS,
          text: "{original}",
          speed: 1,
          delimiter: "",
        },
        duration: 0.8,
      };
      

    
    if (this.$el.querySelectorAll("[data-shuffle='target']").length === 0) {
      textLinkTl.to(this.$el, OPTION);
    } else {
      textLinkTl.to(this.$target, OPTION);
    }

    this.$el.addEventListener("mouseenter", () => {
      textLinkTl.timeScale(1).play();       
    });

    this.$el.addEventListener("mouseleave", () => {
      textLinkTl.timeScale(1.5).reverse();
    });
  }

  shuffleText(params) {

    const { target } = params

    // let textOriginal = target.innerText;
    let textOriginal = target.dataset.text
    // console.log(target)

    let text = gsap.timeline({
        paused: true,
      });


       const OPTION = {
        scrambleText: {
          chars: CHARS,
          text: textOriginal,
          speed: 1,
          delimiter: "",
        },
        duration: 1.2,
        delay: target.dataset.delay || 0,
      };


    text.to(target, OPTION)


      text.timeScale(1).play();
  }
}
